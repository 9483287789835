<template>
 <div class="card card-custom wave wave-animate-fast">
											<div class="card-body">
												<div class="d-flex align-items-center p-5">
													<div class="mr-6">
														<span class="svg-icon svg-icon-primary svg-icon-4x">
															<!--begin::Svg Icon | path:/metronic/theme/html/demo5/dist/assets/media/svg/icons/Weather/Cloud2.svg-->
															        <inline-svg
              src="media/svg/misc/003-puzzle.svg"
            ></inline-svg>
															<!--end::Svg Icon-->
														</span>
													</div>
													<div class="d-flex flex-column">
														<a class="text-dark text-hover-primary font-weight-bold font-size-h4 mb-3">Em breve</a>
														<div class="text-dark-75">Estamos produzindo a documentação para essa integração.</div>
                            <div class="text-dark-75 mt-5">Entre em contato com a gente caso esteja com alguma dúvida sobre como integrar sua plataforma.</div>
													</div>
												</div>
											</div>
										</div>
</template>

<script>
export default {
  name: "integration-comming-soon"
};
</script>

<style></style>
